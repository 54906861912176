import Web3 from "web3";

export function formatNumber(num, defaultFixed) {
    try {
        if (defaultFixed && parseInt(defaultFixed) > 0) {
            defaultFixed = parseInt(defaultFixed);
        } else {
            defaultFixed = 5;
        }

        var numval = num.toString();
        numval = convert(numval);
        var chkDeci = numval.split(".");
        var returnNum = num;
        if (chkDeci.length == 2) {
            if (defaultFixed < chkDeci[1].length) {
                returnNum = toFixedWithoutRound(numval, defaultFixed);
            } else {
                var fix = chkDeci[1].length;
                returnNum = toFixedWithoutRound(numval, fix);
            }
        }
        returnNum = convert(returnNum);
        return returnNum;
    } catch (err) {
        return 0;
    }

}

export function toFixedWithoutRound(amount, dec = 2) {

    try {
        const calcDec = Math.pow(10, dec);
        var withoutFixed = Math.trunc(amount * calcDec) / calcDec;
        withoutFixed = isNumberCheck(withoutFixed);
        return withoutFixed;

    } catch (err) {
        return 0;
    }

}

export function isNumberCheck(amount) {
    var numberVal = amount;
    var convertamt = (isFinite(numberVal) && numberVal > 0 && numberVal !== "Infinity") ? numberVal : 0;
    return convertamt;
}


export function halfAddrShow(addr) {
    if (addr) {
        return addr.substring(0, 6) + "...." + addr.slice(addr.length - 3);
    } else {
        return "";
    }
};



export async function getFormatMulticall(results, name, pos) {

    try {
        var returnVal = (results && results.results && results.results[name]
            && results.results[name].callsReturnContext &&
            results.results[name].callsReturnContext &&
            results.results[name].callsReturnContext[pos] &&
            results.results[name].callsReturnContext[pos].returnValues &&
            results.results[name].callsReturnContext[pos].returnValues[0]) ?
            results.results[name].callsReturnContext[pos].returnValues[0] : "";
        return returnVal;
    } catch (err) {
        return "";
    }
}

export async function getFormatMulticall1(results, name, pos) {

    try {
        var returnVal = (results && results.results && results.results[name]
            && results.results[name].callsReturnContext &&
            results.results[name].callsReturnContext &&
            results.results[name].callsReturnContext[pos] &&
            results.results[name].callsReturnContext[pos].returnValues &&
            results.results[name].callsReturnContext[pos].returnValues) ?
            results.results[name].callsReturnContext[pos].returnValues : "";
        return returnVal;
    } catch (err) {
        return "";
    }
}

export function convert(n) {
    try {
        var sign = +n < 0 ? "-" : "",
            toStr = n.toString();
        if (!/e/i.test(toStr)) {
            return n;
        }
        var [lead, decimal, pow] = n
            .toString()
            .replace(/^-/, "")
            .replace(/^([0-9]+)(e.*)/, "$1.$2")
            .split(/e|\./);
        return +pow < 0
            ? sign +
            "0." +
            "0".repeat(Math.max(Math.abs(pow) - 1 || 0, 0)) +
            lead +
            decimal
            : sign +
            lead +
            (+pow >= decimal.length
                ? decimal + "0".repeat(Math.max(+pow - decimal.length || 0, 0))
                : decimal.slice(0, +pow) + "." + decimal.slice(+pow));
    } catch (err) {
        return 0;
    }
}

export function numberFloatOnly(value) {
    //eslint-disable-next-line
    const regxFormat = /^[]?\d*(?:[.,]\d*)?$/;
    var result = regxFormat.test(value)
    return result;
}