import React, { useEffect, useState } from "react";
// react plugin for creating charts
import ChartistGraph from "react-chartist";
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons

// core components
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";

import CardHeader from "../../components/Card/CardHeader.js";
import CardBody from "../../components/Card/CardBody.js";
import CardFooter from "../../components/Card/CardFooter.js";

import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CustomInput from "../../components/CustomInput/CustomInput.js";
import customInputStyle from "../../assets/jss/material-dashboard-react/components/customInputStyle.js";

import styles from "../../assets/jss/material-dashboard-react/views/dashboardStyle.js";
import Web3 from "web3";
import ICoContarctABI from "../../ABI/ICOContarctABI.json";
import config from "../../lib/config.js";
import moment from "moment";
import ReactLoading from "react-loading";
import { toastAlert } from "../../lib/toastAlert.js";
import { numberFloatOnly, convert, formatNumber } from "../../helper/helper"

const useStyles = makeStyles(styles);

export default function Settings() {
  const classes = useStyles();

  const [Loader, setLoader] = useState(false);
  const [tokenRate, settokenRate] = useState("");
  const [validateError, setValidateError] = useState({});
  const [isUpdate, setisUpdate] = useState(false);

  useEffect(() => {
    getDetails();
  }, []);

  const handleChange = (e) => {
    e.preventDefault();
    const { value } = e.target;
    let status = numberFloatOnly(value);
    console.log(value, status, '>>>>>>>>>')
    if (status) {
      settokenRate(value);
    }

  };

  const getDetails = async () => {
    setLoader(true);
    const web3 = new Web3(config.rpcUrl);
    const ICOcontaract = new web3.eth.Contract(
      ICoContarctABI,
      config.ICOcontractAddress
    );
    let tokenPrice = await ICOcontaract.methods.tokenbusdprice().call();
    var tokenDeci = 6;
    var perToken = tokenPrice * 1 * 10 ** 6;
    perToken = perToken / 10 ** tokenDeci;
    perToken = perToken / 10 ** 18;
    perToken = 1 / perToken;
    perToken = formatNumber(parseFloat(perToken), 18);
    perToken = convert(perToken);
    settokenRate(perToken);
    setLoader(false);
  };

  async function updatePrice() {

    if (!tokenRate || parseFloat(tokenRate) < 0 || isNaN(tokenRate)) {
      toastAlert("error", "Please enter valid price", "price");
      return;
    }
    setisUpdate(true);
    try {
      if (window.ethereum) {
        await window.ethereum.enable();
        const web3 = new Web3(window.ethereum);
        const ICOcontaract = new web3.eth.Contract(
          ICoContarctABI,
          config.ICOcontractAddress
        );
        let owner = await ICOcontaract.methods.owner().call();
        var result = await web3.eth.getAccounts();
        var address = result[0];
        if (address && owner && owner.toLowerCase() == address.toLowerCase()) {
          var cal = 1 / tokenRate;
          cal = cal.toFixed(18);
          cal = cal * 10 ** 18;
          cal = convert(cal.toString());
          let resp = await ICOcontaract.methods.settoken(cal).send({ from: address });
          if (resp && resp.status) {
            toastAlert("success", "Price updated successfully", "price");
          } else {
            toastAlert("error", "Failed to update price", "price");
          }
        } else {
          toastAlert("error", "Please login into admin wallet", "price");
        }

      } else {
        toastAlert("error", "Please login into meta mask", "price");
      }

      setisUpdate(false)
    } catch (err) {
      var msg = (err && err.message) ? err.message : err;
      var pos = msg.search("User denied transaction signature");
      var errMsg = "Oops something wend wrong.!";
      if (pos >= 0) {
        errMsg = "Cancelled";
      }
      setisUpdate(false)
      toastAlert("error", errMsg, "price");
    }

  }

  return (
    <div>
      {Loader == true && (
        <ReactLoading type={"spin"} color={"#000000"} className="loading" />
      )}
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <form
            className={classes.form}
          >
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Update Price($KONG)</h4>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Price($KONGO)"
                    name="tokenprice"
                    value={tokenRate}
                    onChange={handleChange}
                    formControlProps={{
                      fullWidth: true,
                    }}
                  />

                  <span>
                    1 $KONG = {tokenRate} USDT
                  </span>

                  {validateError.AdminName && (
                    <span style={{ color: "red" }}>
                      {validateError.AdminName}
                    </span>
                  )}
                </GridItem>

              </GridContainer>
            </CardBody>
            <CardFooter>
              <Button
                variant="contained"
                color="primary"
                type="button"
                onClick={() => updatePrice()}>
                Update
              </Button>
              {isUpdate &&
                <ReactLoading type={"spin"} color={"#000000"} className="loading" />
              }
            </CardFooter>
          </form>


        </GridItem>
      </GridContainer>
    </div>
  );
}
