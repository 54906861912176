import axios from "axios";
import { decodeJwt } from "./jsonWebToken";
import config from "../lib/config";
import setAuthToken from "../lib/setAuthToken";
import { SET_CURRENT_USER } from "../constant";
//LoginAction

export const login = async (data, dispatch) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/login`,
      data,
    });

    localStorage.setItem("admin_token", respData.data.token);
    setAuthToken(respData.data.token);
    decodeJwt(respData.data.token, dispatch);

    return {
      loading: true,
      result: respData.data.result,
      error: respData.data.errors,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

//AdminProfile

export const getadminDetails = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/adminapi/adminDetails`,
      // headers: {
      //     'Authorization': localStorage.admin_token
      // },
    });
    // console.log(respData, 'checking ResponseData');
    return {
      loading: true,
      status: respData.data.status,
      message: respData.data.message,
      result: respData.data.Data,
    };
  } catch (err) {
    return {
      loading: false,
      status: err?.response?.data?.status,
      message: err?.response?.data?.message,
      error: err.response.data.errors,
    };
  }
};

export const updateProfile = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/updateAdminProfile`,
      // headers: {
      //     'Authorization': localStorage.admin_token
      // },
      data,
    });
    //    console.log(respData, 'chghghecking ResponseData');
    return {
      loading: true,
      status: respData.data.status,
      message: respData.data.message,
      result: respData.data.Data,
      error: respData.data.error,
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response.data.status,
      message: err.response.data.message,
      error: err.response.data.error,
    };
  }
};

export const getFaqId = async (id) => {
  console.log(id, "id");
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/adminapi/getFaq/` + id,
      headers: {
        Authorization: localStorage.admin_token,
      },
    });
    // console.log("result of Data", respData);

    // localStorage.setItem('admin_token', respData.data.token);

    return {
      loading: false,
      result: respData.data.data,
    };
  } catch (err) {
    var sendErr = "";
    if (err) {
      sendErr = err;
      if (err.response) {
        sendErr = err.response;
        if (err.response.data) {
          sendErr = err.response.data;
          if (err.response.data.errors) {
            sendErr = err.response.data.errors;
          }
        }
      }
    }
    return {
      loading: false,
      error: sendErr,
    };
  }
};
export const updateFaq = async (data) => {
  try {
    // console.log(data, "checking data");
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/updateFaq`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      data,
    });
    // console.log(respData, "checking ResponseData");
    return {
      loading: true,
      result: respData.data.result,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};
export const logout = (history) => {
  localStorage.removeItem("admin_token");
  history.push("/Login");
  setAuthToken("");
};
export const setCurrentUser = (decoded) => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded,
  };
};

//faq
export const getFaqList = async (data) => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/adminapi/getFaqList`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      params: data,
    });
    // console.log("result of Data", respData);

    // localStorage.setItem('admin_token', respData.data.token);

    return {
      loading: false,
      result: respData.data.data,
    };
  } catch (err) {
    var sendErr = "";
    if (err) {
      sendErr = err;
      if (err.response) {
        sendErr = err.response;
        if (err.response.data) {
          sendErr = err.response.data;
          if (err.response.data.errors) {
            sendErr = err.response.data.errors;
          }
        }
      }
    }
    return {
      loading: false,
      error: sendErr,
    };
  }
};

export const deleteFaq = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/deleteFaq`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      data,
    });
    // console.log("result of Data", respData);
    return {
      loading: false,
      result: respData,
    };
  } catch (err) {
    var sendErr = "";
    if (err) {
      sendErr = err;
      if (err.response) {
        sendErr = err.response;
        if (err.response.data) {
          sendErr = err.response.data;
          if (err.response.data.errors) {
            sendErr = err.response.data.errors;
          }
        }
      }
    }
    return {
      loading: false,
      error: sendErr,
    };
  }
};
export const addFaq = async (data) => {
  try {
    // console.log(data, "checking data");
    // console.log(config.API_URL, "url");
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/addFaq`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      data,
    });
    // console.log(respData, "checking ResponseData");
    return {
      loading: true,
      result: respData.data.result,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const changepassword = async (data, dispatch) => {
  try {
    console.log(data, "dataa");
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/changePassward`,
      data,
    });
    //
    // localStorage.setItem("admin_token", respData.data.token);
    // setAuthToken(respData.data.token);
    // decodeJwt(respData.data.token, dispatch);

    return {
      loading: true,
      result: respData.data.result,
    };
  } catch (err) {
    var sendErr = "";
    if (err) {
      sendErr = err;
      if (err.response) {
        sendErr = err.response;
        if (err.response.data) {
          sendErr = err.response.data;
          if (err.response.data.errors) {
            sendErr = err.response.data.errors;
          }
        }
      }
    }
    return {
      loading: false,
      error: sendErr,
    };
  }
};

export const checkOtp = async (data) => {
  // console.log(data, "datadatadata");
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/checkOpt`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      data,
    });
    //    // console.log(respData, 'chghghecking ResponseData');
    return {
      loading: true,
      status: respData.data.status,
      message: respData.data.message,
      result: respData.data.Data,
      error: respData.data.error,
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response.data.status,
      message: err.response.data.message,
      error: err.response.data.error,
    };
  }
};

///
export const getUserList = async (data) => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/adminapi/buy-list`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      params: data,
    });

    return {
      loading: false,
      result: respData.data.data,
    };
  } catch (err) {
    var sendErr = "";
    if (err) {
      sendErr = err;
      if (err.response) {
        sendErr = err.response;
        if (err.response.data) {
          sendErr = err.response.data;
          if (err.response.data.errors) {
            sendErr = err.response.data.errors;
          }
        }
      }
    }
    return {
      loading: false,
      error: sendErr,
    };
  }
};
