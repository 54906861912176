import React, { useEffect, useState } from "react";
import DataTable, { createTheme } from "react-data-table-component";
import * as moment from "moment";
import { getUserList } from "../../actions/admin";
import { formatNumber } from "../../helper/helper";
import config from "../../lib/config";

export default function Table() {
  const [users, setUser] = useState();
  const [CopyStatus, setCopyStatus] = useState(true);
  const [CopyIndex, setCopyindex] = useState();

  useEffect(() => {
    getAll();
  }, []);

  const getAll = async () => {
    var res = await getUserList();
    console.log(res, "res");
    setUser(res.result);
  };

  const halfAddrShow = (addr) => {
    if (addr) {
      return addr.substring(0, 10) + "...." + addr.slice(addr.length - 5);
    } else {
      return "";
    }
  };

  async function CopyFunction(text, index) {
    setCopyStatus(false);
    setCopyindex(index);
    navigator.clipboard.writeText(text);
    setTimeout(() => {
      setCopyStatus(true);
    }, 1000);
  }

  const columns = [
    {
      name: "Sno",
      sortable: true,
      cell: (row, index) => <div data-tag="allowRowEvents">{index + 1}</div>,
    },
    {
      name: "Address",
      sortable: true,
      width: "190px",
      cell: (row, index) => (
        <div
          data-tag="allowRowEvents"
          onClick={() => CopyFunction(row.address, index)}
          style={{ cursor: "pointer" }}
        >
          {halfAddrShow(row.address)}{" "}
          {CopyStatus == true ? (
            <i
              class="fa fa-clone"
              aria-hidden="true"
              style={{ fontSize: "15px" }}
            ></i>
          ) : CopyIndex == index ? (
            <i
              class="fa fa-check fa-check-square"
              aria-hidden="true"
              style={{ color: "green", fontSize: "25px" }}
            ></i>
          ) : (
            <i
              class="fa fa-clone"
              aria-hidden="true"
              style={{ fontSize: "15px" }}
            ></i>
          )}
        </div>
      ),
    },
    {
      name: "USDT Amount",
      sortable: true,
      width: "160px",
      cell: (row) => <div data-tag="allowRowEvents">{formatNumber(row.amount / 10 ** 6, 5)}</div>,
    },
    {
      name: "Token Amount($KONG)",
      width: "200px",
      sortable: true,
      cell: (row) => <div data-tag="allowRowEvents">{formatNumber(row.rewardamount / 10 ** 18, 5)}</div>,
    },
    {
      name: "Hash",
      width: "160px",
      sortable: true,
      cell: (row) => (
        <div data-tag="allowRowEvents"><a href={config.txLink + "tx/" + row.hash} target="_blank">{halfAddrShow(row.hash)}</a></div>
      ),
    },
    {
      name: "Date",
      sortable: true,
      width: "160px",
      cell: (row) => (
        <div data-tag="allowRowEvents">
          {moment(row.createdAt).format("MMMM,Do YYYY, hh:mm A")}
        </div>
      ),
    },
  ];

  return (
    <div>
      <h3>Purchase History</h3>
      <DataTable
        columns={columns}
        data={users}
        noHeader
        pagination={true}
        paginationPerPage="10"
        paginationRowsPerPageOptions={[5, 10, 15, 20]}
      />
    </div>
  );
}
