// import lib
import isEmpty from "../../lib/isEmpty";

const validation = (value) => {
  let errors = {},
    emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,6}))$/;
  var paswd = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{7,15}$/;
  if (isEmpty(value.email)) {
    errors.email = "Required";
  } else if (!emailRegex.test(value.email)) {
    errors.email = "Invalid email";
  }

  // if (isEmpty(value.password)) {
  //   errors.password = "Required";
  // }

  // if (isEmpty(value.Newpassword)) {
  //   errors.Newpassword = "Required";
  // }

  // if (isEmpty(value.confirmPassword)) {
  //   errors.confirmPassword = "Required";
  // }
  // } 
  if (isEmpty(value.Newpassword)) {
    errors.Newpassword = "Required"
  }
  // else if (!(paswd.test(value.Newpassword))) {
  //   errors.Newpassword = "Password should contain atleast one uppercase, atleast one lowercase, atleast one number, atleast one special character and minimum 6 and maximum 18 characters "
  // } 
  if (isEmpty(value.confirmPassword)) {
    errors.confirmPassword = "Required"
  }

  return errors;
};

export default validation;
