let key = {};
var ENV_APP_MODE = "production";
if (ENV_APP_MODE === "production") {
  console.log("Set Production Config");
  const API_URL = "https://api.kongmemecoin.io";
  key = {
    baseUrl: `${API_URL}`,
    ICOcontractAddress: "0x6426Ba37f16E2663cE44E48D638259E91aEa46Cc",
    BUSDcontractAddress: "0xdAC17F958D2ee523a2206206994597C13D831ec7",
    KongTokenaddress: "0x014ec6A5a8B1b7aBF7Fb150EE61d6516C21f144A",
    NetworkId: 1,
    rpcUrl: "https://mainnet.infura.io/v3/003dd3bbc4d947eb9e9c1870a5958806",
    txLink: "https://etherscan.io/",
  };
} else
  if (ENV_APP_MODE === "demo") {
    console.log("Set Production Config");
    const API_URL = "https://nicolasido.wearedev.team";
    key = {
      baseUrl: `${API_URL}`,
      ICOcontractAddress: "0xA72904999F366e20E1e3D5789fCdb7931738cd70",//local
      BUSDcontractAddress: "0xC99EaD289f767bc2eF0Ae57A3C1323cc50e3e13f",
      KongTokenaddress: "0xAca4d1B334700B5dc39F0c07dF7651D1be2D47A8",
      NetworkId: 11155111,
      rpcUrl: "https://sepolia.infura.io/v3/d5b89bd22ca242798dd9feb0ab031ccf",
      txLink: "https://sepolia.etherscan.io/"
    };
  } else {
    console.log("Set Development Config");
    const API_URL = "http://localhost";
    key = {
      baseUrl: `${API_URL}:3040`,
      ICOcontractAddress: "0xA72904999F366e20E1e3D5789fCdb7931738cd70",//local
      BUSDcontractAddress: "0xC99EaD289f767bc2eF0Ae57A3C1323cc50e3e13f",
      KongTokenaddress: "0xAca4d1B334700B5dc39F0c07dF7651D1be2D47A8",
      NetworkId: 11155111,
      rpcUrl: "https://sepolia.infura.io/v3/d5b89bd22ca242798dd9feb0ab031ccf",
      txLink: "https://sepolia.etherscan.io/"
    };
  }

export default key;
