import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
// core components
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import CustomInput from "../../components/CustomInput/CustomInput.js";
import Button from "../../components/CustomButtons/Button.js";
import Card from "../../components/Card/Card.js";
import CardHeader from "../../components/Card/CardHeader.js";
import CardAvatar from "../../components/Card/CardAvatar.js";
import CardBody from "../../components/Card/CardBody.js";
import CardFooter from "../../components/Card/CardFooter.js";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import customInputStyle from "../../assets/jss/material-dashboard-react/components/buttonStyle";
import Select from "react-select";

import "react-phone-input-2/lib/style.css";
import { setCurrentUser, changepassword, checkOtp } from "../../actions/admin";
import validation from "../ChangePassword/Validation1";
import isEmpty from "../../lib/isEmpty";

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    // getadminUsers,
    textDecoration: "none",
  },
};

// toaster config
toast.configure();
let toasterOption = {
  position: "top-right",
  autoClose: 2000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

const useStyles = makeStyles(styles);

const customStyle = makeStyles(customInputStyle);

const initialFormValue = {
  email: "",
  password: "",
  Newpassword: "",
  confirmPassword: "",
  otp: "",
};

export default function UserProfile() {
  const customStyles = customStyle();

  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const [formValue, setFormValue] = useState(initialFormValue);
  const [toched, setToched] = useState({});
  const [validateError, setValidateError] = useState({});
  const [changes, setchanges] = useState(false);
  const [loader, setloader] = useState(false);

  const [useremail, setuseremail] = useState("");

  const { password, Newpassword, confirmPassword } = formValue;

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    var paswd = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]$/;
    if (Newpassword != confirmPassword) {
      toast.error("Confirm Password Not Match", toasterOption);
      return false;
    }
    if (isEmpty(password)) {
      toast.error("Please enter your old password", toasterOption);
      return false;
    }
    if (isEmpty(Newpassword)) {
      toast.error("Please enter your new password", toasterOption);
      return false;
    }
    // else if (!paswd.test(Newpassword)) {
    //   toast.error(
    //     "7 to 15 characters which contain at least one numeric digit and a special character",
    //     toasterOption
    //   );
    //   return false;
    // }
    if (isEmpty(confirmPassword)) {
      toast.error("Please enter your confirm password", toasterOption);
      return false;
    }

    setloader(true);

    var token = localStorage.getItem("admin_token");
    var email = token.replace("Bearer ", "");

    console.log(email, "email");
    let reqData = {
      email,
      password,
      Newpassword,
      confirmPassword,
    };

    let { error, result, message } = await changepassword(reqData, dispatch);

    if (!isEmpty(message)) {
      toast.error(message, toasterOption);
      setloader(false);
      return false;
    } else if (isEmpty(error)) {
      // history.push("/Dashboard");
      dispatch(setCurrentUser(result));
      setchanges(true);
      toast.success("Password Update Successfully", toasterOption);
      setloader(false);

      setTimeout(() => {
        // history.push("/faqList");

        window.location.reload();
      }, 2000);
    } else {
      toast.error(message, toasterOption);
      setValidateError(error);
      setloader(false);
    }
  };

  const handleBlur = (e) => {
    const { name } = e.target;
    setToched({ ...toched, ...{ [name]: true } });
  };

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let formData = { ...formValue, ...{ [name]: value } };
    setFormValue(formData);
    setValidateError(validation(formData));
  };

  useEffect(() => {
    setValidateError(validation(formValue));
  }, []);

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <form
              className={classes.form}
              noValidate
              onSubmit={handleFormSubmit}
            >
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>Change Password</h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Old Password"
                      onChange={handleChange}
                      id="password"
                      name="password"
                      onBlur={handleBlur}
                      disabled={changes == true ? true : false}
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                    {changes == true ? (
                      ""
                    ) : (
                      <div>
                        {toched.password && validateError.password && (
                          <p
                            className="error-message text-danger"
                            style={{ color: "red" }}
                          >
                            {validateError.password}
                          </p>
                        )}{" "}
                      </div>
                    )}
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="New password"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      id="Newpassword"
                      name="Newpassword"
                      disabled={changes == true ? true : false}
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                    {changes == true ? (
                      ""
                    ) : (
                      <div>
                        {toched.Newpassword && validateError.Newpassword && (
                          <p className="error-message" style={{ color: "red" }}>
                            {validateError.Newpassword}
                          </p>
                        )}
                      </div>
                    )}
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Confirm Password"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      id="confirmPassword"
                      name="confirmPassword"
                      disabled={changes == true ? true : false}
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                    {changes == true ? (
                      ""
                    ) : (
                      <div>
                        {toched.confirmPassword &&
                          validateError.confirmPassword && (
                            <p
                              className="error-message"
                              style={{ color: "red" }}
                            >
                              {validateError.confirmPassword}
                            </p>
                          )}
                      </div>
                    )}
                  </GridItem>
                </GridContainer>
              </CardBody>
              {loader == false ? (
                <>
                  <CardFooter>
                    <Button color="primary" type="submit">
                      Submit
                    </Button>
                  </CardFooter>
                </>
              ) : (
                <>
                  <CardFooter>
                    <Button disabled color="primary" type="submit">
                      Submit
                    </Button>
                  </CardFooter>
                </>
              )}
            </form>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
